import type { ComposableFunctionArgs } from "~/composables";
import { Logger } from "~/helpers/logger";
import type { PlaceOrderOutput } from "~/modules/GraphQL/types";
import type { UseContextReturn } from "~/types/core";

export const placeOrderCommand = {
	execute: async (
		context: UseContextReturn,
		cartId: string,
		params?: ComposableFunctionArgs<{}>,
	): Promise<PlaceOrderOutput | null> => {
		Logger.info("placeOrderCommand: Starting place magento order...");

		try {
			const { data } = await context.app.$vsf.$magento.api.placeOrder(
				{ cart_id: cartId },
				params?.customQuery ?? null,
				params?.customHeaders,
			);

			if (!data.placeOrder) {
				throw new Error(
					"placeOrderCommand: No data returned from place order command",
				);
			}

			return data.placeOrder;
		} catch (err) {
			Logger.error("placeOrderCommand: Error placing magento order", err);
			throw err;
		}
	},
};
